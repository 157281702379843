import { Card, CardContent, CardHeader, Alert, Container, TextField, Button, Grid, Typography, Stack, LinearProgress, Snackbar, Modal, Box, AlertTitle } from '@mui/material'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import { getCookie } from '../lib/cookie'


import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { timeSince } from '../lib/timeSince'

export default function Payment() {
    let navigate = useNavigate()
    let [data, setData] = useState({ token: getCookie("token") })
    let [loanInfo, setLoanInfo] = useState(null)
    let [open, setOpen] = useState(false)
    let [message, setMessage] = useState(false)

    let getLoanInfo = async (id) => {
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.loans.get_one?id=${id}&token=${getCookie("token")}`, {
            method: 'POST',
            data: JSON.stringify({ token: getCookie("token") })
        })).json())
        setLoanInfo(data.loan)
    }


    let createPayment = async () => {

        // checkLoanLimit - pass for now
        if (!(data.amount) || !(data.phone)) {
            setMessage(`Amount and phone number are required`)
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
            }, 1000)
            return
        }


        // sendLoanRequest
        const params = (new URL(window.location)).searchParams;
        console.log(params)
        console.log()

        let response = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.payments.create?token=${getCookie("token")}`,
            {
                method: 'POST',
                headers: { "Content-Type": "application/json", },
                body: JSON.stringify({ ...data, purpose: params.get('purpose'), id: params.get('id') ?? null })
            }
        )).json())
        console.log(response)
        if (Object.keys(response).indexOf('error') > -1) {
            setMessage(response.error)
            setOpen(!open)
            setTimeout(() => {
                setOpen(!open)
            }, 2000)
            return
        }
        navigate(`/confirm-payment/${response.id}`)
        return
    }

    useEffect(() => {
        const params = (new URL(window.location)).searchParams;
        if (params.get('id')) {
            getLoanInfo(params.get('id'))
        }

    }, [])


    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Header />
                <Container>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={open}
                        onClose={""}
                        message={message}
                        key={"loginAlert"}
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>Create Payment</Typography>
                    <br />
                    <Button variant="solid" sx={{ backgroundColor: "orange", }} onClick={e => { navigate('/payments') }}>Go to My Payments</Button>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} lg={6} md={12}>
                            <br />
                            {loanInfo &&
                                <Alert severity='info'>
                                    <AlertTitle>Loan Repayment Details</AlertTitle>
                                    <Typography variant="h6">Loan Amount: KES {loanInfo.amount}.</Typography>
                                    <Typography>Loan Purpose: {loanInfo.purpose}</Typography>

                                    <Typography>Amount Paid: KES {loanInfo.amount_paid}.</Typography>
                                    <Typography>Balance: KES {loanInfo.loan_balance}.</Typography>

                                    <Typography>Applied: {timeSince(loanInfo.created_at) + " ago."}</Typography>
                                </Alert>}
                            <br />
                            <Card sx={{ backgroundColor: "", border: "1px black solid" }}>

                                <CardContent>
                                    <Alert severity="success">
                                        Provided number will receive STK Push.
                                    </Alert>
                                    <br />
                                    <TextField
                                        autoComplete="none"
                                        sx={{ minWidth: "100%" }}
                                        type="number"
                                        min="0" step="any"
                                        placeholder="Amount in KES."
                                        size="small"
                                        onChange={e => { setData({ ...data, amount: e.target.value }) }}
                                    />
                                    <br /><br />
                                    <TextField
                                        autoComplete="none"
                                        sx={{ minWidth: "100%" }}
                                        type="tel"
                                        placeholder="Mpesa Phone Number."
                                        size="small"
                                        onChange={e => { setData({ ...data, phone: e.target.value }) }}
                                    />
                                    <br />
                                    <br />
                                    <Button variant="contained"
                                        disableElevation onClick={e => { createPayment() }}
                                        sx={{ width: "70%", marginLeft: "15%", backgroundColor: "orange", color: 'black' }}
                                    >MAKE MPESA PAYMENT</Button>
                                </CardContent>
                            </Card>
                        </Grid></Grid>
                </Container>
            </LocalizationProvider>
        </>
    )
}


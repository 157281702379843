import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../components/Header'
import {
    Container, Stack, useMediaQuery,
    Paper, Snackbar, Typography, LinearProgress,
    Divider, Box, TextField, Button
} from '@mui/material'
// import Card
import { getCookie } from '../lib/cookie'


export default function ManageGroup() {

    let { id } = useParams()
    let navigate = useNavigate()
    let isMobile = useMediaQuery('(max-width:600px)');
    let [group, setGroup] = useState(null)
    let [member, setMember] = useState(null)
    let [isAdmin, setIsAdmin] = useState(false)
    let [open, setOpen] = useState(false)
    let [message, setMessage] = useState(false)


    let getGroup = async () => {
        setOpen(false)
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.groups.get_one?id=${id}&token=${getCookie("token")}`, {
            method: 'GET',
        })).json())
        console.log(data)
        setIsAdmin(data.isAdmin)
        setGroup(data.group)
        return
    }
    let addGroupMember = async () => {
        setOpen(false)
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.groups.add_group_member`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: getCookie("token"), group: id, members: [member] })
        })).json())
        console.log(data)
        setGroup(data.group ? data.group : group)
        setOpen(true)
        setMessage(data.message ? data.message : data.error ? data.error : data.exception)
        setTimeout(() => {
            navigate(`/group/${id}`)
        }, 3000);
        return
    }

    useEffect(() => {
        getGroup()
    }, [])



    return (<>

        <Header />
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={""}
            message={message}
            key={"loginAlert"}
        />
        <Container maxWidth="lg">

            {(group && isAdmin) ? <>
                {/* {JSON.stringify(group)} */}

                <Typography variant="h5" sx={{ textDecoration: 'underline' }}>{group.group_name} Management Page</Typography>
                <br />
                <Stack
                    direction={isMobile ? "column" : "row"}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                >
                    
                  
                    <Paper sx={{ backgroundColor: 'white', padding: '2em', borderRadius: '10px' }} elevation={2}>
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>Add Group Members</Typography>
                        <br />
                        <TextField
                            sx={{ minWidth: "100%" }}
                            type="number"
                            placeholder="ID Number"
                            size="small"
                            onChange={e => { setMember(e.target.value) }}
                        />
                        <br /><br />
                        <Button sx={{ backgroundColor: "black" }} variant="contained" onClick={e => { addGroupMember() }}>Add Group Member</Button>

                    </Paper>

                    <Paper sx={{ backgroundColor: '', padding: '2em', borderRadius: '10px' }} elevation={2}>
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>Make Group Contribution</Typography>
                        <br />
                        <TextField
                            sx={{ minWidth: "100%" }}
                            type="text"
                            value={id}
                            disabled
                            size="small"
                            onChange={e => { setMember(e.target.value) }}

                        />
                        <br /><br />
                        <TextField
                            sx={{ minWidth: "100%" }}
                            type="number"
                            placeholder="Amount"
                            size="small"
                            onChange={e => { setMember(e.target.value) }}
                        />
                        <br /><br />
                        <TextField
                            sx={{ minWidth: "100%" }}
                            type="text"
                            placeholder="Notes"
                            size="small"
                            multiline
                            minRows={3}
                            onChange={e => { setMember(e.target.value) }}
                        />
                        <br />
                        <br />
                        <Button sx={{ backgroundColor: "black" }} variant="contained" onClick={e => { navigate(`/groups/manage/${id}`) }}>Make Contribution</Button>

                    </Paper>

                </Stack>


            </> : <>
                <br /><br /><br /><br />
                <LinearProgress color="inherit" />
                <Typography sx={{ textAlign: "center" }} variant="h5">Loading Group Details</Typography>
            </>}
            {/* <br/>
            <Divider orientation="horizontal"/>
            <br /> */}
        </Container>

    </>)


}



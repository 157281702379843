import { useState, useEffect } from 'react'
import {
    Grid, Container, Typography, Card, Divider,
    CardContent, Alert, useMediaQuery
} from '@mui/material'
import Header from '../components/Header'
import { getCookie } from './../lib/cookie'
import { useNavigate } from 'react-router'
import { GiReceiveMoney, GiPayMoney, GiCash, GiChecklist } from 'react-icons/gi'
import { FaCashRegister } from 'react-icons/fa'
import { MdOutlineAccountBox } from 'react-icons/md'
let gradient = { "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }


export default function Index() {

    let [memberInfo, setMemberInfo] = useState(null)
    let [motd, setMotd] = useState('')
    let isMobile = useMediaQuery('(max-width:600px)');


    let navigate = useNavigate()

    let getMemberInfo = async () => {
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.auth.me?token=${getCookie("token")}`,
            { method: "GET", headers: { "Content-Type": "application/json" } })).json())
        console.log(data)
        setMemberInfo(data.member)
    }

    useEffect(() => {
        if (getCookie("token")) {
            setMotd("Increase your Savings to boost your Loan limit.")
            getMemberInfo()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/")
            return
        }
    }, [])


    return (
        <>
            <Header />
            <Container maxWidth="lg" sx={{ backgroundColor: isMobile ? 'orange' : 'white' }} style={isMobile ? gradient : null}>
                <br />
                <br />
                <br />
                <Card sx={{ backgroundColor: isMobile ? 'white' : 'orange', color: isMobile ? 'orange' : 'white', }} style={!isMobile ? gradient : null}>
                    <CardContent>
                        <Typography variant="h5" sx={{ color: isMobile ? 'black' : 'white', fontWeight: 'bold' }}>Welcome {memberInfo ? String(memberInfo.first_name) : "USER"}</Typography>
                        <Divider sx={{ backgroundColor: "white" }}></Divider>
                        <br />
                        <Alert severity="info">{motd}</Alert>
                    </CardContent>
                </Card>
                <br />
                <Typography variant="h5" textAlign="center" sx={{ color: (isMobile ? 'white' : 'black'), textDecoration: 'underline', fontWeight: 'bold' }}>My Services</Typography>
                <br />
                <Grid container>
                    <Icon title="Savings" url="/savings" icon={<GiCash />} />
                    <Icon title="Request Loan" url="/request-loan" icon={<GiReceiveMoney />} />
                    <Icon title="Repay Loan" url="/loan-repayment" icon={<GiPayMoney />} />
                    <Icon title="My Statement" url="/statement" icon={<GiChecklist />} />
                    <Icon title="Payments" url="/payments" icon={<FaCashRegister />} />
                    <Icon title="My Account" url="/my-account" icon={<MdOutlineAccountBox />} />
                </Grid>
                <p></p>
                <br />
                <br />
                <br />
                <br />
                <br />

            </Container>
        </>
    )
}


let Icon = ({ title, url, icon = null }) => {

    let navigate = useNavigate()
    let isMobile = useMediaQuery('(max-width:600px)');


    return (
        <>
            <Grid item xs={6} lg={4} md={6} sx={{ padding: ".4em", textAlign: "center", alignItems: "center", }} onClick={e => { navigate(url) }}>
                <Card sx={{ backgroundColor: isMobile ? 'white' : 'wheat', color: "orange", borderRadius: "10px" }} style={!isMobile ? gradient : null}>
                    <CardContent>
                        <Typography sx={{ textAlign: "center", fontSize: "40px" }} >{icon ? icon : ''}</Typography>
                    </CardContent>
                </Card>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                    {title}
                </Typography>
            </Grid>
        </>
    )
}



import { Card, CardContent, CardHeader, Container, TextField, Button, Grid, Typography, Stack, LinearProgress, Snackbar, Modal, Box } from '@mui/material'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import { getCookie } from '../lib/cookie'


import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export default function RequestLoan() {
    let navigate = useNavigate()
    let [data, setData] = useState({ token: getCookie("token") })
    let [loanLimit, setLoanLimit] = useState(0)
    let [open, setOpen] = useState(false)
    let [message, setMessage] = useState(false)

    let getLoanLimit = async () => {
        let data = (await (await fetch("https://admin.maslaisacco.com/api/method/fosa.api.account.profile",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ "token": getCookie("token") })
            })).json())
        console.log(data)
        setLoanLimit(data.profile.member.loan_limit)
    }


    let requestLoan = async () => {

        // checkLoanLimit - pass for now
        if (!(data.purpose) || !(data.amount)) {
            setMessage(`Loan amount and purpose are required`)
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
            }, 1000)
            return
        }


        if (loanLimit < data.amount) {
            setMessage(`Your loan limit is ${loanLimit}`)
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
            }, 1000)
            return
        }
        


        // sendLoanRequest
        let response = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.loans.apply`,
            {
                method: 'POST',
                headers: { "Content-Type": "application/json", },
                body: JSON.stringify(data)
            }
        )).json())
        console.log(response)
        if (Object.keys(response).indexOf('error') > -1) {
            setMessage(response.error)
            setOpen(!open)
            return
        }
        navigate(`/loans/${response.loan.name}`)
        return
    }

    useEffect(() => {
        getLoanLimit()
    }, [])


    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Header />
                <Container>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={open}
                        onClose={""}
                        message={message}
                        key={"loginAlert"}
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>Request Loan</Typography>
                    <br />
                    <Button variant="solid" sx={{ backgroundColor: "orange", }} onClick={e => { navigate('/loans') }}>Go to Loans List</Button>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} lg={6} md={12}>
                            <br />
                            <Card sx={{ maxWidth: "500px", backgroundColor: "", border: "1px black solid" }}>

                                <CardContent>
                                    <Typography variant='h6'>Loan Limit: KES {loanLimit}</Typography>
                                    <p></p>
                                    <TextField
                                        autoComplete="none"
                                        sx={{ minWidth: "100%" }}
                                        type="number"
                                        min="0" step="any"
                                        placeholder="Amount in KES."
                                        size="small"
                                        onChange={e => { setData({ ...data, amount: e.target.value }) }}
                                    />
                                    <br /><br />
                                    <TextField
                                        autoComplete="none"
                                        sx={{ minWidth: "100%" }}
                                        type="text"
                                        multiline
                                        minRows={3}
                                        placeholder="Loan Purpose"
                                        size="small"
                                        onChange={e => { setData({ ...data, purpose: e.target.value }) }}
                                    />
                                    <br />
                                    <br />
                                    <Button variant="contained"
                                        disableElevation onClick={e => { requestLoan() }}
                                        sx={{ width: "50%", marginLeft: "25%", backgroundColor: "orange", color: 'black' }}
                                    >Request Loan</Button>
                                </CardContent>
                            </Card>
                        </Grid></Grid>
                </Container>
            </LocalizationProvider>
        </>
    )
}


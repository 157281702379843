import { Card, CardContent, CardHeader, Container, Select, Button, Grid, Typography, FormControl, InputLabel, Snackbar, MenuItem, Box } from '@mui/material'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import { getCookie } from '../lib/cookie'


import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export default function Repayment() {

    let navigate = useNavigate()
    let id = useParams()
    let [data, setData] = useState({ token: getCookie("token") })
    let [unpaid, setUnpaid] = useState([])
    let [selected, setSelected] = useState(null)
    let [loanLimit, setLoanLimit] = useState(0)


    let getPendingLoans = async () => {

        let response = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.loans.get_many`,
            {
                method: 'POST',
                headers: { "Content-Type": "application/json", },
                body: JSON.stringify({ ...data, status: "Unpaid" })
            }
        )).json())
        setUnpaid(response.loans)
        console.log(response)
        return;
    }

    let makeRepayment = async () => {
        if (selected) {
            navigate(`/create-payment?purpose=loan&id=${selected}`)
            return;
        }
        return;
    }

    useEffect(() => {

        if (getCookie("token")) {
            getPendingLoans()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/repayment")
            return
        }

    }, [])


    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Header />
                <Container>
                    <br /><br /><br /><br />
                    <Typography variant="h5" sx={{ textDecoration: "underline" }}>Make Loan Repayment</Typography>
                    <br />
                    <Button variant="solid" sx={{ backgroundColor: "orange", }} onClick={e => { navigate('/loans') }}>View Previous Loans</Button>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} lg={6} md={12}>
                            <br />
                            <br />
                            <Card sx={{ maxWidth: "500px", backgroundColor: "", border: "1px black solid" }}>

                                <CardHeader sx={{ color: "orange" }}></CardHeader>
                                <CardContent>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Unpaid Loan</InputLabel>
                                        <Select
                                            // value={age}
                                            label="Select Unpaid Loan"
                                            onChange={e => { setSelected(e.target.value) }}
                                        >
                                            {unpaid.length > 0 && unpaid.map((item) => {
                                                return <MenuItem value={item.name}>{`${item.purpose} - ${item.amount}`}</MenuItem>
                                            })}

                                        </Select>
                                    </FormControl>
                                    <br /><br />


                                    <br />
                                    <Button variant="contained"
                                        disableElevation onClick={e => { makeRepayment() }}
                                        sx={{ width: "70%", marginLeft: "15%", backgroundColor: "orange" }}
                                    >Make Repayment</Button>
                                </CardContent>
                            </Card>
                        </Grid></Grid>
                </Container>
            </LocalizationProvider>
        </>
    )
}


import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import {
    Container, Stack, Button,
    Paper, Box, Typography, LinearProgress,
    Divider, Alert, AlertTitle
} from '@mui/material'
import { getCookie } from '../lib/cookie'
import { timeSince } from '../lib/timeSince'
import { useNavigate } from 'react-router-dom'


let gradient = { "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }

export default function PaymentDetails() {

    let { id } = useParams()
    // let isMobile = useMediaQuery('(max-width:600px)');
    let [payment, setPayment] = useState(null)
    let navigate = useNavigate()


    let getRepayment = async () => {
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.payments.get_one?id=${id}&token=${getCookie("token")}`, {
            method: 'POST',
            data: JSON.stringify({ token: getCookie("token") })
        })).json())
        console.log(data)
        setPayment(data.payment)
    }

    useEffect(() => {

        if (getCookie("token")) {
            getRepayment()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/payments")
            return
        }

    }, [])


    return (<>

        <Header />
        <Container maxWidth="lg">
            <br /><br /><br /><br />
            <Typography variant="h5" sx={{ textDecoration: "underline" }}>Payment Details</Typography>
            <br />
            <Button variant="solid" sx={{ backgroundColor: "orange", }} elevation={0} onClick={e => { navigate('/payments') }}>View all My Payments</Button>
            <br />
            <br />
            {payment ? <>
                {/* {JSON.stringify(repayment)} */}

                <Stack direction={"column"} spacing="8">
                    <Paper sx={{ minWidth: "50%", padding: "1em", backgroundColor: "orange", borderRadius: '7px' }} elevation={0} style={gradient}>
                        <Container sx={{ padding: ".5em" }} >
                            <Box sx={{ border: "2px white solid", padding: "1em", borderRadius: '7px', backgroundColor: 'white' }}>
                                <Typography variant="h6">Amount</Typography>
                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>KES {payment.amount}</Typography>
                            </Box>
                            <Box sx={{ padding: '1em' }}>
                                <Typography variant="p">MPESA Receipt: {payment.mpesa_receipt ?? "-"}</Typography>
                                <Typography variant="h6">Purpose: {payment.purpose === "loan" ? "Loan Repayment" : "Savings" || ""}</Typography>

                                <Typography variant="h6">Status: {payment.status}</Typography>
                            </Box>
                        </Container>
                        <Divider orientation="horizontal" />
                        <p></p>
                        <Typography variant="p" sx={{ padding: '1em' }}>Created: {`${timeSince(payment.created_at)} ago`}</Typography>
                        <br />

                    </Paper>
                    <br />
                </Stack>
            </> : <>
                <br /><br /><br /><br />
                <LinearProgress color="inherit" />
                <Typography sx={{ textAlign: "center" }} variant="h5">Loading Payment Details</Typography>
            </>}
        </Container>

    </>)


}





import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import { Container, Stack, Button, 
        Paper, Box, Typography, LinearProgress, 
        Divider, Alert, AlertTitle
} from '@mui/material'
import {getCookie} from '../lib/cookie'
import { useNavigate } from 'react-router-dom'


let gradient = { "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }

export default function RepaymentDetails() {

    let { id } = useParams()
    // let isMobile = useMediaQuery('(max-width:600px)');
    let [repayment, setRepayment] = useState(null)
    let navigate = useNavigate()


    let getRepayment  = async () => {
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.repayments.get_one?id=${id}&token=${getCookie("token")}`, {
            method:'POST',
            data: JSON.stringify({token: getCookie("token")})
        })).json())
        console.log(data)
        setRepayment(data.repayment)
    }

    useEffect(() => {

        if (getCookie("token")) {
            getRepayment()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/reservations")
            return
        }

    }, [])



    return (<>

        <Header />
        <Container maxWidth="lg">
            <br/><br/><br/><br/>
            <Typography variant="h5" sx={{textDecoration:"underline"}}>Repayment Details</Typography>
            <br/>
            {repayment ? <>
                {/* {JSON.stringify(repayment)} */}
               
                <Stack direction={"column"} spacing="8">
                    <Paper sx={{minWidth:"50%", padding:"1em", backgroundColor:"orange", borderRadius:'7px'}} elevation={0} style={gradient}>
                        <Container sx={{padding:".5em"}} >
                            <Box sx={{border:"2px white solid", padding: "1em", borderRadius:'7px', backgroundColor:'white'}}>
                            <Typography variant="h6">Amount Paid</Typography>
                            <Typography variant="h4" sx={{fontWeight:"bold"}}>KES {repayment.amount_paid}</Typography>
                            <Typography variant="h5">Balance: <b>KES {repayment.loan_amount - repayment.amount_paid}</b></Typography>
                            </Box>
                            <Box sx={{padding:'1em'}}>
                            <Typography variant="h5">Loan Amount: <b>KES {repayment.loan_amount}</b></Typography>
                            <Typography variant="h6">Status: {repayment.status}</Typography>
                            <Typography variant="p">Loan ID: {repayment.loan}</Typography>
                            </Box>
                        </Container>
                        <Divider orientation="horizontal"/>
                        <Typography variant="p" sx={{padding:'1em'}}>Approval: {(new Date(repayment.created_at).toLocaleString())}</Typography>
                        <br/>
                        <br/>
                        <Button variant="contained" sx={{ backgroundColor: "white", color:'orange' }} elevation={0} onClick={e => { navigate('/loan-repayments') }}>Back to My repayments</Button>                        
                    </Paper>
                    <br/>
                    <Typography variant="h6" sx={{textAlign:"center"}}>Repayments</Typography>
                </Stack>
            </> : <>
            <br/><br/><br/><br/>
             <LinearProgress color="inherit" />
             <Typography sx={{textAlign:"center"}} variant="h5">Loading Repayment Details</Typography>
            </>}
        </Container>

    </>)


}





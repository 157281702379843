import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import {
    Container, useMediaQuery, Card, LinearProgress,
    Divider, Grid, Typography, CardContent, CardHeader
} from '@mui/material'
import { getCookie } from '../lib/cookie';
import { timeSince } from '../lib/timeSince'



export default function Statement() {
    let isMobile = useMediaQuery('(max-width:600px)');

    let [statement, setStatement] = useState(null)
    let getStatement = async () => {
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.account.statement?token=${getCookie('token')}`)).json())
        console.log(data)
        if (data.status === "success") {
            setStatement(data.statement)
            return
        }
        return

    }

    useEffect(() => {
        getStatement()
    }, [])



    return (<>

        <Header />
        <Container maxWidth="lg">
            <Typography variant="h5" sx={{ textDecoration: "underline" }}>
                My Statement
            </Typography>
            <br />
            {statement ? <>
                {/* {JSON.stringify(listing)} */}
                <br />
                <Typography variant="h6">Balance</Typography>
                <Typography variant="h4">KES {statement.balance}.</Typography>
                <br />
                <Grid container spacing={2}>
                    {(statement) && ['payments', 'savings', 'loans'].map((item) => {
                        return <Grid item xs={12} lg={4}>
                            <StatementItem title={item} data={statement[item]} />
                        </Grid>
                    })}
                </Grid>
            </> : <>
                <br /><br /><br /><br />
                <LinearProgress color="inherit" />
                <Typography sx={{ textAlign: "center" }} variant="h5">Loading Statement</Typography>
            </>}
            <br />
            <Divider orientation="horizontal" />
            <br />
        </Container>

    </>)

}

let StatementItem = ({ title, data }) => {

    return (
        <>
            <Card sx={{ minWidth: "30%", padding: ".5em", border: "1px solid black" }} elevation={0}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title.toUpperCase()}
                    </Typography>
                    {data && Object.keys(data).map((item) => {
                        console.log(item)
                        return <>
                            <Divider />
                            <p></p>
                            {data[item].amount && <Typography variant="h5">{"KES "} {data[item].amount}</Typography>}
                            {data[item].status && <Typography variant="h6">{"Status: "} {data[item].status}</Typography>}
                            {data[item].purpose && <Typography variant="h6">{"Purpose: "} {data[item].purpose === "loan" ? "Loan Repayment" : "Savings" || ""}</Typography>}
                            {data[item].updated_at && <Typography >{"Time: "} {timeSince(data[item].updated_at) + " ago"}</Typography>}
                            {data[item].created_at && <Typography >{"Time: "} {timeSince(data[item].created_at) + " ago"}</Typography>}
                        </>
                    })}
                </CardContent>

            </Card>
        </>
    )
}




import { useState, useEffect } from 'react'
import { AppBar, Typography, Box, Toolbar, Button, Tooltip, Menu, MenuItem, IconButton, Avatar, Container, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import { getCookie } from '../lib/cookie';


export default function Header() {
  let title = "Maslai SACCO"
  let pages = [
    { 'My Loans': "/loans" },
    { 'My Statement': "/statement" },
    { 'Make Repayment': "/loan-repayment" },
    { 'My Savings': "/savings" },
    { 'My Loan Repayments': "/loan-repayments" },
    { 'My Payments': "/payments" },
    { 'My Account': "/my-account" },
    { 'Register Group': "/register-group" },
    { 'Groups': "/groups" },

  ]
  let isMobile = useMediaQuery('(max-width:600px)');
  let navigate = useNavigate()
  const settings = [{ 'My Account': '/my-account' }, { 'Logout': "/login" },];

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    return
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    return
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    return
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    return
  };

  useEffect(() => {
    if (getCookie("token")) {
      return
    } else {
      navigate('/login')
      window.localStorage.setItem("next_page", "/")
      return
    }
  }, [])
  let gradient = { "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }


  return (
    <>
      <AppBar position="fixed" sx={{ color: 'white', backgroundColor: 'orange' }} style={gradient}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
              onClick={e => { navigate('/') }}
            >
              {title}
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'inline-block' } }}>
              {isMobile ? <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton> : ''}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'block' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={Object.keys(page)[0]} onClick={handleCloseNavMenu} sx={{ color: "orange" }}>
                    <Typography textAlign="center" onClick={e => { navigate(`${page[Object.keys(page)[0]]}`); return }}>{Object.keys(page)[0]}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              onClick={e => { navigate('/') }}
            >
              {title}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={Object.keys(page)[0]}
                  onClick={e => { navigate(`${page[Object.keys(page)[0]]}`); handleCloseNavMenu(); return }}
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >
                  {Object.keys(page)[0]}
                </Button>
              ))}
            </Box>

            {getCookie("token") ? <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={Object.keys(setting)[0]} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" onClick={e => { navigate(`${setting[Object.keys(setting)[0]]}`); return }}>{Object.keys(setting)[0]}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> : <Button variant="outlined" onClick={e => { navigate('/login') }} sx={{ color: "orange" }}>LOGIN</Button>}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}





import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import {
    Container, useMediaQuery, Card, LinearProgress,
    Divider, Grid, Typography, CardContent, Button, CardActions
} from '@mui/material'
import {timeSince} from '../lib/timeSince'

import { getCookie } from '../lib/cookie';
let gradient = { "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }



export default function Savings() {
    let isMobile = useMediaQuery('(max-width:600px)');
    let navigate = useNavigate()

    let [savings, setSavings] = useState([])
    let getSavings = async () => {
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.savings.get_many?token=${getCookie('token')}`)).json())
        console.log(data)
        setSavings(data.savings)
    }

    useEffect(() => {
        getSavings()
    }, [])



    return (<>

        <Header />
        <Container maxWidth="lg">
            <br /><br /><br /><br />
            {savings ? <>

                {/* {JSON.stringify(listing)} */}
                <Typography variant="h5" sx={{ textDecoration: "underline" }}>My Savings</Typography>
                <br />
                <Button variant="solid" sx={{ backgroundColor: "orange", float: "right" }} onClick={e => { navigate('/create-payment?purpose=saving') }}>TOP UP SAVINGS</Button><br />

                <Grid container spacing={2}>
                    {(savings.length > 0) && savings.map((item) => {
                        return <Grid item xs={12} lg={4}>
                            <SavingItem data={item} />
                        </Grid>
                    })}
                </Grid>
            </> : <>
                <br /><br /><br /><br />
                <LinearProgress color="inherit" />
                <Typography sx={{ textAlign: "center" }} variant="h5">Loading Savings</Typography>
            </>}
            <br />
            <Divider orientation="horizontal" />
            <br />
        </Container>

    </>)


}

let SavingItem = ({ data }) => {
    let navigate = useNavigate()
    return (
        <>
            <Card sx={{ border: "1px solid black", backgroundColor: "orange" }} style={gradient} onClick={e => { navigate(`/saving/${data.name}`) }} elevation={0}>
                <CardContent>
                    <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>KES {data.amount}</Typography>
                    <Typography>Status: <b>{data.status}</b></Typography>
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>{`${timeSince(data.updated_at)} ago`}</Typography>
                    <Divider orientation="horizontal" />
                </CardContent>
                <CardActions>
                    <Button variant="outlined" sx={{ color: "white" }} size="small" color="primary">
                        More Info
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}




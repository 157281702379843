import { useState, useEffect } from 'react'
import Header from '../components/Header'
import { Container, Grid, Typography, LinearProgress, Button, Card, CardContent, Divider, CardActions } from '@mui/material'
import { getCookie } from '../lib/cookie'
import { useNavigate } from 'react-router'

let gradient ={"background":"-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)","filter":"progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )"}

export default function Groups() {

    let [loans, setLoans] = useState(null)
    let navigate = useNavigate()
    let getLoans = async () => {

        let data = (await (await fetch("https://admin.maslaisacco.com/api/method/fosa.api.groups.all",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ "token": getCookie("token") })
            })).json())
        console.log(data)
        setLoans(data.groups)
    }

    useEffect(() => {

        if (getCookie("token")) {
            getLoans()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/reservations")
            return
        }

    }, [])

    return (<>
        <Header />
        <Container maxWidth="lg">
            <br/>
            <br/>
            <br/>
            <Typography variant="h5">
                My Groups
            </Typography>
            <br />
            {!loans &&
                <>
                    <br /><br /><br /><br />
                    <LinearProgress color="inherit" />
                    <Typography sx={{ textAlign: "center" }} variant="h5">Loading Groups</Typography>
                </>}
            <Grid container justifyContent="center"
                alignItems="center" spacing={2}>
                {loans && loans.map((reservation) => {
                    return <Grid item xs={12} lg={4}>
                        <LoanItem data={reservation} />
                    </Grid>
                })}
            </Grid>
        </Container>
    </>)
}


let l = {
    "member": "Test",
    "amount": 30000,
    "status": "Approved",
    "purpose": "School Fees",
    "approved_by": "Admin One",
    "repayment_status": "Partially Paid",
    "note": "",
    "created_at": "3 weeks ago",
    "updated_at": "2 days ago",
    "repayment_balance": 20000,
    "name": "e4r5t6y7"
}

let LoanItem = ({ data }) => {
    let navigate = useNavigate()
    return (
        <>
            <Card sx={{ border: "1px solid black", backgroundColor: "orange" }} style={gradient} onClick={e => { navigate(`/group/${data.name}`) }} elevation={0}>
                <CardContent>
                    <Typography variant="h5" sx={{color:'white', fontWeight:'bold'}}>{data.group_name}</Typography>
                    <Typography>Status: <b>{data.status}</b></Typography>
                    <Divider orientation="horizontal" />

                    <Typography>Description: {data.description}</Typography>
                    {/* <Typography>Balance: {(data.amount) - (data.repayment_balance)}</Typography> */}

                </CardContent>
                <CardActions>
                    <Button variant="outlined" sx={{ color: "white" }} size="small" onClick={e => { navigate(`/group/${data.name}`) }} color="primary">
                        More Info
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}



import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import {
    Container, Typography, LinearProgress,
    Divider, Paper
} from '@mui/material'
import { getCookie } from '../lib/cookie'
import { useNavigate } from 'react-router-dom'
let gradient = { "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }


export default function Account() {

    let [profile, setProfile] = useState(null)
    let navigate = useNavigate()
    let getProfile = async () => {
        let data = (await (await fetch("https://admin.maslaisacco.com/api/method/fosa.api.account.profile",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ "token": getCookie("token") })
            })).json())
        console.log(data)
        setProfile(data.profile)
    }

    useEffect(() => {

        if (getCookie("token")) {
            getProfile()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/reservations")
            return
        }
    }, [])

    return (<>

        <Header />
        <Container maxWidth="lg">
            <br /><br /><br /><br />
            <Typography variant="h5" sx={{ textDecoration: "underline" }}>
                Account Details
            </Typography><br />
            {
                profile ? <>
                    <Paper sx={{ padding: "2em", color: "white" }} style={gradient}>
                        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>Name</Typography>
                        <Typography variant="h5">{profile.account.member_name}</Typography>
                        <br />
                        <Divider orientation="horizontal" />
                        <br />
                        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>Account No.</Typography>
                        <Typography variant="h5">{profile.account.account_number}</Typography>
                        <br />
                        <Divider orientation="horizontal" />
                        <br />
                        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>Phone No: </Typography>
                        <Typography variant="h6">{profile.member.phone}</Typography>
                        <br />
                        <Typography variant="h6">Email: {profile.member.email}</Typography>
                        <Typography variant="h6">ID No: {profile.member.id_number}</Typography>
                        <br />
                        <Divider orientation="horizontal" />
                        <br />
                        <Typography variant="h6">Loan Limit:</Typography>
                        <Typography variant="h4">KES {profile.member.loan_limit}</Typography>

                        <br />
                        <Divider orientation="horizontal" />
                        <br />

                        <Typography variant="h5">Account Status</Typography>
                        <Typography variant="h6">Status: {profile.member.status}</Typography>
                        <Typography variant="h6">Verified: {profile.member.verified}</Typography>

                    </Paper>

                </> : <>
                    <br /><br /><br /><br />
                    <LinearProgress color="inherit" />
                    <Typography sx={{ textAlign: "center" }} variant="h5">Loading Account Info</Typography>
                </>}
            <br />
            <Divider orientation="horizontal" />
            <br />
        </Container>

    </>)
}





import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Register from './pages/Register'
import Savings from './pages/Savings'
import Saving from './pages/Saving'
import Login from './pages/Login'
import Logout from './pages/Logout'
import LoanRepayments from './pages/LoanRepayments'
import Index from './pages/Index'
import Loans from './pages/Loans'
import Loan from './pages/Loan'
import RequestLoan from './pages/RequestLoan'
import ConfirmPayment from './pages/ConfirmPayment'
import ResetPassword from './pages/ResetPassword'
import SetNewPassword from './pages/SetNewPassword'
import Account from './pages/Account'
import Groups from './pages/Groups'
import Group from './pages/Group'
import Statement from './pages/Statement'
import Payments from './pages/Payments'
import Repayment from './pages/Repayment'
import RegisterGroup from './pages/RegisterGroup'
import ManageGroup from './pages/ManageGroup'
import RepaymentDetails from './pages/RepaymentDetails'
import Payment from './pages/Payment'
import PaymentDetails from './pages/PaymentDetails'


function App() {
    return ( 
        <div>
      <Router>
          <Routes>
          <Route path="/loan-repayments" element={<LoanRepayments/>} />
          <Route path="/loan-repayment" element={<Repayment/>} />
          <Route path="/repayment/:id" element={<RepaymentDetails/>} />
          <Route path="/my-account" element={<Account/>} />
          <Route path="/login" element={<Login/>}/>
          <Route path="/logout" element={<Logout/>}/>
          <Route path="/register" element={<Register/>} />
          <Route path="/savings" element={<Savings/>} />
          <Route path="/saving/:id" element={<Saving/>} />
          <Route path="/register-group" element={<RegisterGroup/>} />
          <Route path="/payments" element={<Payments/>} />
          <Route path="/payment/:id" element={<PaymentDetails/>} />
          <Route path="/statement" element={<Statement/>} />
          <Route path="/reset-password" element={<ResetPassword/>} />
          <Route path="/set-new-password" element={<SetNewPassword/>} />
          <Route path="/loans" element={<Loans/>} />
          <Route path="/request-loan" element={<RequestLoan/>} />
          <Route path="/confirm-payment/:id" element={<ConfirmPayment/>} />
          <Route path="/groups/manage/:id" element={<ManageGroup/>} />
          <Route path="/loans/:id" element={<Loan/>} />
          <Route path="/" element={<Index/>} />
          <Route path="/create-payment" element={<Payment/>} />
          <Route path="/groups" element={<Groups/>} />
          <Route path="/group/:id" element={<Group/>} />
          
        </Routes>
    </Router>
        </div>
    );
}

export default App;
import { useState, useEffect } from 'react'
import Header from '../components/Header'
import { Container, Grid, Typography, LinearProgress, Button, Card, CardContent, Divider, CardActions } from '@mui/material'
import { getCookie } from '../lib/cookie'
import { useNavigate } from 'react-router'
import { timeSince } from '../lib/timeSince'


export default function Loans() {

    let [loans, setLoans] = useState(null)
    let navigate = useNavigate()
    let getLoans = async () => {

        let data = (await (await fetch("https://admin.maslaisacco.com/api/method/fosa.api.loans.get_many",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ "token": getCookie("token") })
            })).json())
        setLoans(data.loans)
    }

    useEffect(() => {
        if (getCookie("token")) {
            getLoans()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/loans")
            return
        }

    }, [])

    return (<>
        <Header />
        <Container maxWidth="lg">
            <br/>
            <br/>
            <br/>
            <br/>
            <Typography variant="h5" sx={{textDecoration:"underline"}}>
                My Loans
            </Typography>
            <Button variant="solid" sx={{ backgroundColor: "orange", float: "right" }} onClick={e => { navigate('/request-loan') }}>Request Loan</Button>
            {!loans &&
                <>
                    <br /><br /><br /><br />
                    <LinearProgress color="inherit" />
                    <Typography sx={{ textAlign: "center" }} variant="h5">Loading Loan Applications</Typography>
                </>}
            <Grid container justifyContent="center"
                alignItems="center" spacing={2}>
                {loans && loans.map((reservation) => {
                    return <Grid item xs={12} lg={4}>
                        <LoanItem data={reservation} />
                    </Grid>
                })}
            </Grid>
        </Container>
    </>)
}


let LoanItem = ({ data }) => {
    let navigate = useNavigate()
    return (
        <>
            <Card sx={{ border: "1px solid black", backgroundColor: "orange", fontSize:'large' }} style={{"background":"-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)","filter":"progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )"}} onClick={e => { navigate(`/loans/${data.name}`) }} elevation={0}>
                <CardContent >
                    <Typography variant="h5" sx={{color:'white'}}>KES. {data.amount}</Typography>
                    <Typography>Status: <b>{data.status}</b></Typography>
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold"}}>Applied: {`${timeSince(data.created_at)} ago`}</Typography>
                    <Divider orientation="horizontal" />
                    
                    <Typography sx={{fontWeight:"bold"}}>Balance: {(data.amount) - (data.repayment_balance)}</Typography>
                </CardContent>
                <CardActions>
                    <Button variant="outlined" sx={{ color: "orange", backgroundColor:'white' }} size="small" >
                        Loan Details
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}



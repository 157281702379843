import { Card, CircularProgress, AlertTitle, Alert, Container, TextField, Button, Grid, Typography, Stack, LinearProgress, Snackbar, Modal, Box } from '@mui/material'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import { getCookie } from '../lib/cookie'


import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export default function Payment() {
    let navigate = useNavigate()
    let { id } = useParams()
    let [paymentStatus, setPaymentStatus] = useState(false)
    let [open, setOpen] = useState(false)
    let [error, setError] = useState(false)
    let [message, setMessage] = useState(false)

    let getPaymentStatus = async () => {
        let response = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.payments.check_status?id=${id}&token=${getCookie("token")}`,
            {
                headers: { "Content-Type": "application/json" }
            }
        )).json())
        console.log(response)
        if (response.status === "success") {
            if (response.payment === "Success") {
                setPaymentStatus(true)
                setTimeout(() => {
                    navigate('/payments')
                }, 1500)
                return
            }
            await getPaymentStatus()
        }
        if(response.status !== "success"){
            setError(response.status !== "success")
            setTimeout(() => {
                navigate(`/`)
            }, 1500)
            return
        }  
    }
    useEffect(() => {
        getPaymentStatus()
    }, [])


    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Header />
                <Container>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={open}
                        onClose={""}
                        message={message}
                        key={"loginAlert"}
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                    {!(paymentStatus) && !(error) && <Box>
                        <CircularProgress />
                        <Typography variant="h5">Confirming Payment</Typography>
                        <br />
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            Enter your PIN on the pop-up that appears on your screen to authorize payment.
                        </Alert>
                    </Box>}

                    {paymentStatus && <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        Payment Confirmed.
                    </Alert>}
                    {error && <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        Could not verify payment.
                    </Alert>}
                </Container>
            </LocalizationProvider>
        </>
    )
}


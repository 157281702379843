import { Card, CardContent, CardHeader, Container, TextField, Button, Grid, Typography, Snackbar } from '@mui/material'
import { useEffect, useState, } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCookie } from '../lib/cookie'
import Header from '../components/Header'

export default function Register() {

    let [registration, setRegistration] = useState(null)
    let navigate = useNavigate()
    let [open, setOpen] = useState(false)
    let [message, setMessage] = useState(false)
    let [user, setUser] = useState(null)

    let getUser = async () => {
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.auth.me`,
            {
                method: 'POST',
                headers: { "Content-Type": "application/json", },
                body: JSON.stringify({token: getCookie('token')})
            }
        )).json())
        if(data.status === 'success'){
            setUser(data.member.first_name + " " + data.member.surname)
            setRegistration({ ...registration, group_admin: data.member.name })
            return
        }
        setMessage(data.error)
        setOpen(!open)
        return
        
    }
    useEffect(() => {
        getUser()
    }, [])


    let register = async () => {
        let requiredFields = ['group_name', 'description',]
        for (let i; i < requiredFields.length; i++) {
            if (!(requiredFields[i] in Object.keys(registration))) {
                console.log(`${requiredFields[i]} is missing`)
                setMessage(`${requiredFields[i]} is required`)
                setOpen(!open)
                return
            }
        }
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.groups.register`,
            {
                method: 'POST',
                headers: { "Content-Type": "application/json", },
                body: JSON.stringify({data:registration, token: getCookie('token')})
            }
        )).json())
        if (data.status === "error") {
            setMessage(data.error)
            setOpen(!open)
            return
        }
        navigate('/groups')
        console.log(data)
        return
    }

    return (
        <>
            <Header/>
            <Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={open}
                    onClose={""}
                    message={message}
                    key={"registerAlert"}
                />
                <br/>
                <br/>
                <br/>
                <br/>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} lg={6} md={12}>
                        <Typography variant="h5" sx={{textAlign:"center"}}>Maslai SACCO Groups</Typography>
                        <br/>   
                        <Card sx={{ maxWidth: "500px", backgroundColor: "", border: "1px black solid" }}>
                            <CardHeader title="Register Sacco Group" sx={{ color: "orange" }}></CardHeader>
                            <CardContent>
                                <TextField
                                    sx={{ minWidth: "100%" }}
                                    type="text"
                                    placeholder="Group Name"
                                    size="small"
                                    onChange={e => { setRegistration({ ...registration, group_name: e.target.value }) }}
                                />
                                <br /><br />
                                <TextField
                                    sx={{ minWidth: "100%" }}
                                    type="textarea"
                                    placeholder="Description"
                                    multiline
                                    rows={4}
                                    size="small"
                                    onChange={e => { setRegistration({ ...registration, description: e.target.value }) }}
                                />
                                <br /><br />
                                <TextField
                                    type="text"
                                    placeholder={user ? ("Group Admin: " + user) :"Group Admin"}
                                    sx={{ minWidth: "100%" }}
                                    size="small"
                                    value={user ? ("Group Admin: " + user) : ''}
                                    disabled
                                />
                                <br />
                                <br />
                                <Button disableElevation variant="contained" sx={{ width: "50%", marginLeft: "25%", backgroundColor: "orange" }}
                                    onClick={e => { register() }}
                                >Register Group</Button>
                            </CardContent>
                        </Card>
                        <br />
                        <Typography sx={{ textDecoration: "underline" }}
                            textAlign="center"
                        ><a href="/">Back HOME.</a></Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    )

}





import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import {
    Container, Stack, Button,
    Paper, Box, Typography, LinearProgress,
    Divider, Alert, AlertTitle, useMediaQuery
} from '@mui/material'
import { getCookie } from '../lib/cookie'
import { useNavigate } from 'react-router-dom'
import { timeSince } from '../lib/timeSince'



let gradient = { "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }

export default function LoanDetails() {

    let { id } = useParams()
    let isMobile = useMediaQuery('(max-width:600px)');
    let [loan, setLoan] = useState(null)
    let navigate = useNavigate()


    let getLoan = async () => {
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.loans.get_one?id=${id}&token=${getCookie("token")}`, {
            method: 'POST',
            data: JSON.stringify({ token: getCookie("token") })
        })).json())
        setLoan(data.loan)
    }

    useEffect(() => {

        if (getCookie("token")) {
            getLoan()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/reservations")
            return
        }

    }, [])



    return (<>

        <Header />
        <Container maxWidth="lg">
            <br /><br /><br /><br />
            <Typography variant="h5" sx={{ textDecoration: "underline" }}>Loan Details</Typography>
            <br />
            <Button variant="solid" sx={{ backgroundColor: "orange"}} elevation={0} onClick={e => { navigate('/loans') }}>Go to Loans List</Button>
            <br/>

            {loan ? <>
                {/* {JSON.stringify(loan)} */}
                <br />
                {(loan.status === "Applied") &&
                    <Alert severity="info">
                        <AlertTitle>Awaiting Approval</AlertTitle>
                        Kindly wait as your loan application is being processed.
                    </Alert>
                }
                <br />
                <Stack direction={"column"} spacing="8">
                    <Paper sx={{ minWidth: "50%", padding: "1em", backgroundColor: "orange", borderRadius: '7px' }} elevation={0} style={gradient} >
                        <Container sx={{ padding: ".5em" }} >
                            <Box sx={{ border: "2px white solid", padding: "1em", borderRadius: '7px', backgroundColor: 'white' }}>
                                <Typography variant="h6">Loan Amount</Typography>
                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>KES {loan.amount}</Typography>
                            </Box>

                            <Box sx={{ padding: '1em' }}>
                                <Typography variant="h6">Purpose: {loan.purpose ? loan.purpose : "N/A"}</Typography>
                                <Typography variant="h6">Loan Status: {loan.status}</Typography>
                                <Typography variant="p">Loan ID: {loan.name}</Typography>
                            </Box>
                        </Container>
                        <Divider orientation="horizontal" />
                        <p></p>
                        <Typography variant="p" sx={{ padding: '2em' }}>Date: {`${timeSince(loan.created_at)} ago`}</Typography>
                    </Paper>
                    <br />
                    {loan.status === "Approved" &&
                        <Button variant="solid" sx={{ backgroundColor: "orange", color: 'white' }} elevation={0} onClick={e => { navigate(`/create-payment?purpose=loan&id=${id}`) }}>Make Loan Repayment</Button>
                    }
                </Stack>
            </> : <>
                <br /><br /><br /><br />
                <LinearProgress color="inherit" />
                <Typography sx={{ textAlign: "center" }} variant="h5">Loading Loan Details</Typography>
            </>}
        </Container>

    </>)


}





import { useState, useEffect } from 'react'
import Header from '../components/Header'
import { Container, Grid, Typography, LinearProgress, Card, CardContent, Divider, CardActions, Button } from '@mui/material'
import { getCookie } from '../lib/cookie'
import { useNavigate } from 'react-router'
import { timeSince } from '../lib/timeSince'

export default function Payment() {

    let [payments, setPayments] = useState(null)
    let navigate = useNavigate()
    let getPayments = async () => {

        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.payments.get_many?token=${getCookie("token")}`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ "token": getCookie("token") })
            })).json())
        console.log(data)
        setPayments(data.payments)
    }

    useEffect(() => {

        if (getCookie("token")) {
            getPayments()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/payments")
            return
        }

    }, [])

    return (<>
        <Header />
        <Container maxWidth="lg">
            <br /><br /><br /><br />
            <Typography variant="h5" sx={{ textDecoration: "underline" }}>
                My Payments
            </Typography>
            <Button variant="solid" sx={{ backgroundColor: "orange", float: "right" }} onClick={e => { navigate('/create-payment') }}>MAKE PAYMENT</Button><br />

            {!payments ?
                <>
                    <br /><br /><br /><br />
                    <LinearProgress color="inherit" />
                    <Typography sx={{ textAlign: "center" }} variant="h5">Loading Payments</Typography>
                </> :
                (payments.length === 0) ? <>
                    <br />
                    <Typography sx={{ textAlign: "center" }} variant="h5">No Payments Found.</Typography>
                    <br />
                    <Typography sx={{ textAlign: "center" }} variant="h6">Start Saving today.</Typography>
                </> : <></>
            }
            <Grid container justifyContent="center"
                alignItems="center" spacing={2}>
                {payments && payments.map((item) => {
                    return <Grid item xs={12} lg={4}>
                        <PaymentItem data={item} />
                    </Grid>
                })}
            </Grid>
        </Container>
    </>)
}

let p = {


}

let PaymentItem = ({ data }) => {
    let navigate = useNavigate()
    return (
        <>
            <Card sx={{ border: "1px solid black", backgroundColor: "orange" }} style={{ "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }} onClick={e => { navigate(`/payment/${data.name}`) }} elevation={0}>
                <CardContent>
                    <Typography variant="h4" sx={{ color: "white", fontWeight: "bold" }}>KES {data.amount}.</Typography>
                    <Typography>Payment Status: {data.status}</Typography>
                    <Typography variant="h6">Purpose: {data.purpose === "loan" ? "Loan Repayment" : "Savings" || ""}</Typography>
                    <p></p>
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>{`${timeSince(data.updated_at)} ago`}</Typography>
                </CardContent>
                <CardActions>
                    <Button variant="outlined" sx={{ color: "white" }} size="small" color="primary">
                        More Info
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}


import { useState, useEffect } from 'react'
import Header from '../components/Header'
import { Container, Grid, Typography, LinearProgress, Card, CardContent, Divider, CardActions, Button } from '@mui/material'
import { getCookie } from '../lib/cookie'
import { useNavigate } from 'react-router'
import {timeSince} from '../lib/timeSince'

let gradient = { "background": "-webkit-linear-gradient(90deg, hsla(30, 100%, 50%, 1) 41%, hsla(11, 80%, 57%, 1) 100%)", "filter": "progid: DXImageTransform.Microsoft.gradient( startColorstr=\"#FF7F00\", endColorstr=\"#E95D3C\", GradientType=1 )" }

export default function LoanRepayments() {

    let [loans, setLoans] = useState(null)
    let navigate = useNavigate()
    let getLoans = async () => {
        let data = (await (await fetch("https://admin.maslaisacco.com/api/method/fosa.api.repayments.get_many",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ "token": getCookie("token") })
            })).json())
        setLoans(data.repayments)
    }

    useEffect(() => {
        if (getCookie("token")) {
            getLoans()
            return
        } else {
            navigate('/login')
            window.localStorage.setItem("next_page", "/loan-repayments")
            return
        }

    }, [])

    return (<>
        <Header />
        <Container maxWidth="lg">
            <br /><br /><br /><br />
            <Typography variant="h5" sx={{ textDecoration: "underline" }}>
                Loan Repayments
            </Typography>
            <br /><br />
            {!loans &&
                <>
                    <br /><br /><br /><br />
                    <LinearProgress color="inherit" />
                    <Typography sx={{ textAlign: "center" }} variant="h5">Loading Loan Repayments</Typography>
                </>}
            <Grid container justifyContent="center"
                alignItems="center" spacing={2}>
                {loans && loans.map((reservation) => {
                    return <Grid item xs={12} lg={4}>
                        <RepaymentItem data={reservation} />
                    </Grid>
                })}
            </Grid>
        </Container>
    </>)
}


let RepaymentItem = ({ data }) => {
    let navigate = useNavigate()
    return (
        <>
            <Card sx={{ border: "1px solid black", backgroundColor: "orange" }} onClick={e => { navigate(`/repayment/${data.name}`) }} style={gradient} elevation={0}>
                <CardContent>
                    <Typography variant="h5" sx={{color:'white', fontWeight:"bold"}}>Loan Amount: KES {data.loan_amount}</Typography>
                    <Typography variant="p" sx={{fontSize:'large'}}>Amount Paid: KES {data.amount_paid}</Typography>
                    <Typography variant="h6">Balance: KES {data.loan_amount - data.amount_paid}</Typography>
                    <Divider orientation="horizontal" />
                    <Typography variant="p">Approval Date: {`${timeSince(data.created_at)} ago`}</Typography>

                </CardContent>
                <CardActions>
                    <Button variant="outlined" sx={{ color: "white" }} size="small" color="primary">
                        More Info
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}







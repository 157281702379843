import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {timeSince} from '../lib/timeSince'

import Header from '../components/Header'
import {
    Container, Stack,
    Paper, Snackbar, Typography, LinearProgress,
    Divider, Box, Button
} from '@mui/material'
// import Card
import { getCookie } from '../lib/cookie'


export default function Group() {

    let { id } = useParams()
    let navigate = useNavigate()
    // let isMobile = useMediaQuery('(max-width:600px)');
    let [group, setGroup] = useState(null)
    let [isAdmin, setIsAdmin] = useState(false)
    let [open, setOpen] = useState(false)
    let [message, setMessage] = useState(false)


    let getGroup = async () => {
        setOpen(false)
        let data = (await (await fetch(`https://admin.maslaisacco.com/api/method/fosa.api.groups.get_one?id=${id}&token=${getCookie("token")}`, {
            method: 'GET',
        })).json())
        console.log(data)
        setIsAdmin(data.isAdmin)
        setGroup(data.group)
        setMessage(data.message ? data.message : data.error ? data.error : data.exception)
        setOpen(true)
        return
    }

    useEffect(() => {
        getGroup()
    }, [])



    return (<>

        <Header />
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={""}
            message={message}
            key={"loginAlert"}
        />
        <Container maxWidth="lg">
            <Typography variant="h5">Group Details</Typography>

            {group ? <>
                {/* {JSON.stringify(group)} */}
                <br />
                <Stack direction={"column"} spacing="8">
                    <Paper sx={{ minWidth: "50%", padding: "1em", backgroundColor: "orange" }} elevation={0}>
                        <Typography variant="h5" sx={{ textDecoration: 'underline', color: 'white' }}>Name: <b>{group.group_name}</b></Typography>
                        <br />
                        <Divider orientation="horizontal" />
                        <br />
                        <Box sx={{ border: '1px solid black', padding: '1em', fontSize: 'large' }} >
                            <Typography variant="p"><u>Description:</u> {group.description}</Typography><br /><br />
                            <Typography variant="p">Created: {timeSince(group.created_at)}</Typography><br />
                            <Typography variant="p">Group Status: <b>{group.status}</b></Typography><br />
                            <Typography variant="p">Group ID: {group.name}</Typography>
                        </Box>
                        <br />
                        <Divider orientation="horizontal" />
                        <br />
                        <Button variant="contained" sx={{ backgroundColor: "gray" }} elevation={0} onClick={e => { navigate('/groups') }}>Back to My Groups</Button>
                    </Paper>
                    <br />


                    {isAdmin && <Button sx={{ backgroundColor: "black" }} variant="contained" onClick={e => { navigate(`/groups/manage/${id}`) }}>Manage Group</Button>}
                    <br />
                    <Typography variant="h6">Group Members ({group.members.length})</Typography>
                    {group && group.members.map((member) => {
                        return (<>
                            <Box sx={{ padding: "2em", border: "1px solid black" }}>

                                <Typography variant='p'>Names: {member.member_names}</Typography><br />
                                <Typography variant='p'>ID Number: {member.member}</Typography><br />
                                <Typography variant='p'>Phone Number: {member.member_phone}</Typography>

                            </Box>
                            <br />
                        </>
                        )
                    })}

                </Stack>
            </> : <>
                <br /><br /><br /><br />
                <LinearProgress color="inherit" />
                <Typography sx={{ textAlign: "center" }} variant="h5">Loading Group Details</Typography>
            </>}
        </Container>

    </>)


}




